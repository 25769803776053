// Hero Section
.category-block {
	box-shadow: 0px 7px 16px 0px rgba(0, 0, 0, 0.09);
	padding:20px 25px;
	border-radius: 3px;
	margin-bottom: 25px;
	.header {
		margin-bottom:20px;
		border-bottom:3px solid #F4F7F9;
		text-align: center;
		h4 {
			font-size:18px;
			font-weight: 700;
			// text-transform: uppercase;
			margin-top:5px;
		}
		.icon-bg-1 {
			background: #ccdb38;
			box-shadow:0 0 0px 4px rgba(204, 219, 56, 0.35);
		}
		.icon-bg-2 {
			background: #a676b8;
			box-shadow:0 0 0px 4px rgba(166, 118, 184, 0.35);
		}
		.icon-bg-3 {
			background: #fe7e17;
			box-shadow:0 0 0px 4px rgba(254, 126, 23, 0.35);
		}
		.icon-bg-4 {
			background: #EC3A56;
			box-shadow:0 0 0px 4px rgba(236, 58, 86, 0.35);
		}
		.icon-bg-5 {
			background: #1DBFFF;
			box-shadow:0 0 0px 4px rgba(29, 191, 255, 0.35);
		}
		.icon-bg-6 {
			background: #02d3a4;
			box-shadow:0 0 0px 4px rgba(2, 211, 164, 0.35);
		}
		.icon-bg-7 {
			background: #bc02d3;
			box-shadow:0 0 0px 4px rgba(188, 2, 211, 0.35);
		}
		.icon-bg-8 {
			background: #025fd3;
			box-shadow:0 0 0px 4px rgba(2, 95, 211, 0.35);
		}
		i {
			margin-right: 6px;
			color:$white;
			font-size: 13px;
			width: 35px;
			height: 35px;
			line-height: 35px;
			text-align: center;
			margin-bottom:6px;
			border-radius: 40px;
		}
	}
	.category-list{
		li{
			display: block;
			font-size: 14px;
			border-bottom:1px solid #F4F7F9;
			padding: 10px 0;
			&:last-child {
				border:none;
			}
			a{
				display: block;
				color:#777;
				font-size: 13px;
				span {
					float: right;
					font-size:10px;
					border-radius: 10px;
					padding:3px 8px;
					color:#84919B;
				}
				&:hover{
					text-decoration: underline;
					}
				i{
					margin-right: 6px;
					font-size: 12px;
					color: $primary-color;
					vertical-align:middle;
				}
			}
		}
	}
}


.search-result {
	padding: 20px;
	margin-bottom:20px;
	p {
		margin:0;
	}
}

.category-search-filter {
	background: #f7f7f7;
	padding:20px;
	strong {
		margin:0 5px;
	}
	.view {
		text-align: right;
		ul {
			display: inline-block;
			li {
				&:not(:last-child) {
					margin-right:10px;
				}
				a {
					font-size:15px;
				}
				
			}
		}
		.view-switcher {
			background: $white;
			padding: 10px 15px;
		}
	}

}

.nice-select {
	float: none;
    display: inline-block;
    border-radius: 0;
    .list {
    	border-radius: 0;
    }
}

//  Client Slider 

.arrow-right,.arrow-left {
	cursor: pointer;
	width: 35px;
	height: 35px;
	line-height: 35px;
	background: #025fd3;
	text-align: center;
	color: #fff;
	border-radius: 50%;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	z-index: 2;
}
.arrow-right {
	right: -18px;
}
.arrow-left {
	left: -18px;
}