.about-content {
    p {
        line-height: 30px;
    }
}
.counter-content {
    i {
        font-size: 60px;
        color: $primary-color;
    }
    span {
        font-size: 24px;
    }
}